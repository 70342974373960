import img1 from "../../Assets/Imgs/img1.jpeg";
import img2 from "../../Assets/Imgs/img2.jpeg";
import img3 from "../../Assets/Imgs/img3.jpeg";
import img4 from "../../Assets/Imgs/img4.jpeg";
import img5 from "../../Assets/Imgs/img5.jpeg";
export const SliderData = [
  {
    image: img1,
  },
  {
    image: img2,
  },
  {
    image: img3,
  },
  {
    image: img4,
  },
  {
    image: img5,
  },
];
